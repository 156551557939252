// 客户来源
let customerSource = () => {
    let arr = [{
            value: 1,
            label: '自然进店'
    },
    {
            value: 2,
            label: '陌电开发'
    },
    {
            value: 3,
            label: '小区开发'
    },
    {
            value: 4,
            label: '卖场拦截'
    },
    {
            value: 5,
            label: '人脉'
    },
    {
            value: 6,
            label: '展会活动'
    },
    {
            value: 7,
            label: '网络获客'
    },
    {
            value: 8,
            label: '总部分配'
    },
    {
            value: 9,
            label: '其他'
        },
    ]
    return arr;
}

// 客户户型
let houseType = () => {
    let arr = [{
            value: 1,
            label: '一室'
    },
    {
            value: 2,
            label: '二室'
    },
    {
            value: 3,
            label: '三室'
    },
    {
            value: 4,
            label: '四室'
    },
    {
            value: 5,
            label: '多室'
    },
		{ value: 8, label: '大平层' }, { value: 9, label: '别墅' },
    {
            value: 6,
            label: '自建'
        },
        // {
        // 	value: 7,
        // 	label: '工程'
        // },
    ]
    return arr;
}

// 客户级别
let customerLevel = () => {
    let arr = [{
            value: 1,
            label: 'A类客户'
    },
    {
            value: 2,
            label: 'B类客户'
    },
    {
            value: 3,
            label: 'C类客户'
    },
    {
            value: 4,
            label: 'D类客户'
        },
    ]
    return arr;
}

// 客户跟进状态
let followStatus = () => {
        let arr = [{
                value: 1,
                label: '线索'
            },
            {
                value: 2,
                label: '意向'
            },
            {
                value: 3,
                label: '成单'
            },
            {
                value: 4,
                label: '交付'
            },
            {
                value: 7,
                label: '复购'
            },
            {
                value: 8,
                label: '联购'
            },
            {
                value: 5,
                label: '丢单'
            },
            {
                value: 6,
                label: '无效'
            },
        ]
        return arr;
    }

// 经销商客户跟进状态
let followStreamStatus = () => {
    let arr = [{
            value: 1,
            label: '线索(未跟进)'
        },
        {
            value: 2,
            label: '线索(已跟进)'
        },
        {
            value: 3,
            label: '意向'
        },
        {
            value: 4,
            label: '签单'
        },
        {
            value: 5,
            label: '交付'
        },
        {
            value: 6,
            label: '流失'
        },
        {
            value: 7,
            label: '无效'
        },
    ]
    return arr;
}
    // 留店时长
let stayTime = () => {
    let arr = [{
            value: 1,
            label: '0-10分钟'
    },
    {
            value: 2,
            label: '10-30分钟'
    },
    {
            value: 3,
            label: '30分钟以上'
        },
    ]
    return arr;
}

// 需求状态
let saleChanceStatus = () => {
        let arr = [{
                value: 1,
                label: '跟进中'
            },
            {
                value: 2,
                label: '已签约'
            },
            {
                value: 3,
                label: '已交付'
            },
            {
                value: 4,
                label: '已流失'
            },
            {
              value: 6,
              label: '其他'
            }
        ]
        return arr;
    }
    // 需求状态(含已下单)
let saleChanceStatusNew = () => {
    let arr = [{
            value: 1,
            label: '跟进中'
    },
    {
            value: 2,
            label: '已签约'
    },
    {
            value: 3,
            label: '已交付'
    },
    {
            value: 4,
            label: '已流失'
    },
    {
            value: 5,
            label: '已下单'
        },
    ]
    return arr;
}

// 跟进方式
let followType = () => {
        let arr = [{
                value: 1,
                label: '电话拜访'
            },
            {
                value: 2,
                label: '网络拜访'
            },
            {
                value: 3,
                label: '当面拜访'
            },
            {
                value: 4,
                label: '进店接待'
            },
        ]
        return arr;
    }
    // 装修阶段
let fixStage = () => {
        let arr = [{
                value: 1,
                label: '设计'
            },
            {
                value: 2,
                label: '拆改'
            },
            {
                value: 3,
                label: '水电'
            },
            // {
            // 	value: 4,
            // 	label: '防水'
            // },
            {
                value: 5,
                label: '泥瓦'
            },
            {
                value: 6,
                label: '木工'
            },
            {
                value: 7,
                label: '油漆'
            },
            {
                value: 8,
                label: '软装'
            },
            {
                value: 9,
                label: '未开工'
            },
        ]
        return arr;
    }
    // 流失原因
let lostReason = () => {
        let arr = [{
                value: 1,
                label: '价格原因'
            },
            {
                value: 2,
                label: '产品质量'
            },
            {
                value: 3,
                label: '款式花色'
            },
            {
                value: 4,
                label: '设计方案'
            },
            {
                value: 5,
                label: '服务体验'
            },
            {
                value: 6,
                label: '外界影响'
            },
            {
                value: 7,
                label: '品牌认知'
            },
            {
                value: 8,
                label: '说不清楚'
            },
        ]
        return arr;
    }
    // 跟进模板
let followModel = () => {
        let arr = [{
                value: 1,
                label: '普通跟进'
            },
            {
                value: 5,
                label: '智能跟进'
            },
            {
                value: 2,
                label: '进店邀约'
            },
            {
                value: 3,
                label: '测量邀约'
            },
        ]
        return arr;
    }
    // 分配类型 1.自主录入2.手动分配 3:营销云 4:服务云 5-营销云公海客户 6-投放公海客户 7-导入公海客户，8-人脉客户，9内推客户,10回收公海
let allocateType = () => {
        let arr = [{
            value: 1,
            label: '自主录入'
        }, {
            value: 2,
            label: '手动分配'
        }, {
            value: 3,
            label: '营销云'
        }, {
            value: 4,
            label: '服务云'
        }, {
            value: 5,
            label: '营销云公海客户'
        }, {
            value: 6,
            label: '投放公海客户'
        }, {
            value: 7,
            label: '导入公海客户'
        }, {
            value: 8,
            label: '人脉客户'
        }, {
            value: 9,
            label: '内推客户'
        }, {
            value: 10,
            label: '回收公海'
        }, ]
        return arr;
    }
    // 客户类型
let customerTypeText = () => {
        let arr = [{
                value: 1,
                label: '自主拓客'
            },
            {
                value: 2,
                label: '分配客户'
            },
            {
                value: 3,
                label: '营销云客户'
            },
            {
                value: 5,
                label: '公海客户'
            },
            {
                value: 6,
                label: '公海客户'
            },
            {
                value: 7,
                label: '公海客户'
            },
            {
                value: 8,
                label: '公海客户'
            },
            {
                value: 9,
                label: '内推客户'
            },
            {
                value: 10,
                label: '公海客户'
            },
        ]
        return arr;
    }
    // 客户类型
let customerType = () => {
        let arr = [{
                value: 1,
                label: '自主拓客'
            },
            {
                value: 2,
                label: '分配客户'
            },
            {
                value: 3,
                label: '营销云客户'
            },
            {
                value: 5,
                label: '公海客户'
            },
            {
                value: 9,
                label: '内推客户'
            },
            {
                value: 20,
                label: '云屏客户'
            },
        ]
        return arr;
    }
    // 客户类型
let distributeType = () => {
        let arr = [{
                value: 1,
                label: '自主拓客'
            },
            {
                value: 2,
                label: '分配客户'
            },
            {
                value: 3,
                label: '营销云客户'
            },
            {
                value: 5,
                label: '营销云公海客户'
            },
            {
                value: 6,
                label: '投放公海客户'
            },
            {
                value: 7,
                label: '导入公海客户'
            },
            {
                value: 8,
                label: '人脉公海客户'
            },
            {
                value: 9,
                label: '内推客户'
            },
            {
                value: 10,
                label: '回收客户'
            },
        ]
        return arr;
    }
    // 星级
let starRating = () => {
        let arr = [{
                value: 0,
                label: '0星'
            },
            {
                value: 1,
                label: '0.5星'
            },
            {
                value: 2,
                label: '1星'
            },
            {
                value: 3,
                label: '1.5星'
            },
            {
                value: 4,
                label: '2星'
            },
            {
                value: 5,
                label: '2.5星'
            },
            {
                value: 6,
                label: '3星'
            },
            {
                value: 7,
                label: '3.5星'
            },
            {
                value: 8,
                label: '4星'
            },
            {
                value: 9,
                label: '4.5星'
            },
            {
                value: 10,
                label: '5星'
            },
        ]
        return arr;
    }
    // 需求级别
let demandLevel = () => {
    let arr = [{
            value: 1,
            label: 'A'
    },
    {
            value: 2,
            label: 'B'
    },
    {
            value: 3,
            label: 'C'
    },
    {
            value: 4,
            label: 'D'
        },
    ]
    return arr;
}

// 获取数据label数组
let getLabelArr = (oDataName) => {
        let list = eval(oDataName + '()');
        let oArr = [];
        for (let i = 0; i < list.length; i++) {
            oArr.push(list[i].label)
        }
        return oArr;
    }
    // 根据value获取对应label
let getLabel = (oDataName, oValue) => {
    let list = eval(oDataName + '()');
    let oLabel = '';
    for (let i = 0; i < list.length; i++) {
        if (oValue == list[i].value) {
            oLabel = list[i].label
        }
    }
    return oLabel;
}

//服务单状态
let getStatusText = (status) => {
    let arr = [{
            value: 0,
            label: '待确认'
    },
    {
            value: 1,
            label: '进行中'
    },
    {
            value: 2,
            label: '已完成'
    },
    {
            value: 3,
            label: '已撤销'
    },
    {
            value: 4,
            label: '已撤销'
        }
    ]
    return arr;
}


// 需求级别
let sellChangeLevel = () => {
    let arr = [{
            value: 0,
            label: 'A类需求、B类需求、C类需求、D类需求'
    },
    {
            value: 1,
            label: 'A类需求'
    },
    {
            value: 2,
            label: 'B类需求'
    },
    {
            value: 3,
            label: 'C类需求'
    },
    {
            value: 4,
            label: 'D类需求'
        },
    ]
    return arr;
}

// 投放渠道
let formatChannel = () => {
    let arr = [{
        value: null,
        label: '无'
    }, {
        value: '',
        label: '无'
    }, {
        value: 1,
        label: '支付宝'
    }, {
        value: 2,
        label: '百度'
    }, {
        value: 3,
        label: '今日头条'
    }, {
        value: 4,
        label: '抖音短视频'
    }, {
        value: 5,
        label: '抖音短视频'
    }, {
        value: 6,
        label: '自定义渠道'
    }, {
        value: 7,
        label: '微信朋友圈'
    }, ]
    return arr;
}

// 选择投放渠道
let formatChooseChannel = () => {
    let arr = [{
            value: 1,
            label: '支付宝'
    },
    {
            value: 2,
            label: '百度'
    },
    {
            value: 3,
            label: '今日头条'
    },
    {
            value: '4,5',
            label: '抖音短视频'
    },
    {
            value: 6,
            label: '自定义渠道'
        }, {
            value: 7,
            label: '微信朋友圈'
        },
    ]
    return arr;
}

// 选择需求状态
let formatSellStage = () => {
    let arr = [{
            value: '1',
            label: '跟进中'
    },
    {
            value: '2',
            label: '已签约'
    },
    {
            value: '3',
            label: '已交付'
    },
    {
            value: '4',
            label: '已流失'
        }
    ]
    return arr;
}

// 设置中选择需求状态
let formatSetSellStage = () => {
    let arr = [{
            value: '1',
            label: '跟进中'
    },
    {
            value: '2',
            label: '已签约'
    },
    {
            value: '3',
            label: '已交付'
        }
    ]
    return arr;
}

// 选择服务状态
let formatServiceType = () => {
    let arr = [{
            value: '4',
            label: '全部'
    },
    {
            value: '0',
            label: '未确认'
    },
    {
            value: '1',
            label: '已确认'
    },
    {
            value: '2',
            label: '已完成'
    },
    {
            value: '3',
            label: '已撤销'
        }
    ]
    return arr;
}

// 客户营销云活动标签
let activityLabel = () => {
    let arr = [{
            value: '1',
            label: '浏览*次'
    },
    {
            value: '2',
            label: '报名*次'
    },
    {
            value: '3',
            label: '购卡*次'
    },
    {
            value: '4',
            label: '观看直播*次'
    },
    {
            value: '5',
            label: '下单*次'
        },
        // {
        // 	value: '6',
        // 	label: '仅参与免费领好礼'
        // },
        {
            value: '7',
            label: '领券*次'
        }
    ]
    return arr;
}

// 装修风格
let decorationStyle = () => {
    let arr = [
        { value: 1, label: '美式' },
        { value: 2, label: '北欧' },
        { value: 3, label: '中式' },
        { value: 4, label: '日式' },
        { value: 5, label: '法式' },
        { value: 6, label: '极简' },
        { value: 7, label: '现代' },
        { value: 8, label: '轻奢' },
        { value: 9, label: '复古' },
        { value: 10, label: '工业风' },
        { value: 11, label: '欧式' },
        { value: 12, label: '其他' }
    ]
    return arr;
}

// 智能意向
let intentionLevel = () => {
    let arr = [
        // {
        //     value: 1,
        //     label: '低意向'
        // },
        {
            value: 2,
            label: '中意向'
    },
    {
            value: 3,
            label: '高意向'
        }
    ]
    return arr;
}

// 需求进度
let demandProgressLevel = () => {
    let arr = [{
            value: 1,
            label: '已进店'
    },
    {
            value: 2,
            label: '已出方案'
    },
    {
            value: 3,
            label: '已测量'
        }
    ]
    return arr;
}

// 选择客户行为
let sellChangeStage = () => {
    let arr = [{
        value: '1',
        label: '进店未签'
    }, {
        value: '2',
        label: '需求下单'
    }]
    return arr;
}

// 客户信息设置
let customerInfo = () => {
    let arr = [{
            value: 1,
            label: '客户名称'
    },
    {
            value: 2,
            label: '联系方式'
    },
    {
            value: 3,
            label: '客户来源'
    },
    {
            value: 4,
            label: '来源区域'
        },
    {
            value: 5,
            label: '客户户型'
    },
    {
            value: 13,
            label: '住址省市区'
        },
    {
            value: 14,
            label: '街道'
        },
    {
            value: 15,
            label: '小区'
    },
    {
            value: 6,
            label: '房屋面积'
    },
    {
            value: 7,
            label: '装修阶段'
    },
    {
            value: 12,
            label: '装修风格'
    },
    {
            value: 8,
            label: '性别'
    },
    {
            value: 9,
            label: '生日'
    },
    {
            value: 10,
            label: '年龄'
    },
    {
            value: 11,
            label: '客户简介'
        },
    ]
    return arr;
}

// 跟进信息设置
let followInfo = () => {
    let arr = [{
            value: 1,
            label: '跟进内容',
            columnType: 1
    },
    {
            value: 2,
            label: '客户名称',
            columnType: 2
    },
    {
            value: 3,
            label: '关联需求',
            columnType: 3
    },
    {
            value: 4,
            label: '跟进方式',
            columnType: 3
    },
    {
            value: 5,
            label: '图片',
            columnType: 4
    },
    {
            value: 6,
            label: '当面拜访的接待定位',
            columnType: 5
    },
    {
            value: 7,
            label: '进店接待留店时长',
            columnType: 3
    },
    {
            value: 8,
            label: '进店接待的接待定位',
            columnType: 5
        }
    ]
    return arr;
}
// 需求信息
let demandInfo = () => {
    let arr = [{
        value: 0,
        label: '需求编码'
    }, {
        value: 1,
        label: '需求品牌'
    }, {
        value: 2,
        label: '需求类型'
    }, {
        value: 3,
        label: '客户关键信息'
    }, {
        value: 4,
        label: '关联人脉'
    }, {
        value: 5,
        label: '需求跟进人'
    }, {
        value: 6,
        label: '需求共享人'
    }, {
        value: 7,
        label: '需求内推人'
    }, {
        value: 14,
        label: '需求来源'
    }, {
        value: 8,
        label: '竞争对手'
    }, {
        value: 9,
        label: '竞争对手描述'
    }, {
        value: 10,
        label: '预估金额'
    }, {
        value: 11,
        label: '备注'
    }, {
        value: 12,
        label: '关联商品'
    }, {
        value: 13,
        label: '营销活动'
    }, ]
    return arr;
}

// 字段类型
let columnType = () => {
    let arr = [{
        value: 1,
        label: '多行文本框',
    // }, {
    //     value: 2,
    //     label: '单行文本框',
    }, {
        value: 3,
        label: '单选框',
    // }, {
    //     value: 4,
    //     label: '图片',
    // }, {
    //   value: 5,
    //   label: '地址定位',
    }, {
			value: 11,
			label: '多选框'
		}, {
      value: 6,
      label: '附件',
    }, {
      value: 7,
      label: '数字文本',
    }, {
      value: 8,
      label: '级联单选',
    }, ]
    return arr;
}

// 服务单字段类型
let columnServiceType = () => {
    let arr = [{
        value: 1,
        label: '多行文本框',
    // }, {
    //     value: 2,
    //     label: '单行文本框',
    }, {
        value: 3,
        label: '单选框',
    // }, {
    //     value: 4,
    //     label: '图片',
    // }, {
    //   value: 5,
    //   label: '地址定位',
    // }, {
    //   value: 6,
    //   label: '附件',
    }, {
      value: 7,
      label: '数字文本',
    }, {
        value: 8,
        label: '级联单选',
    }, {
        value: 9,
        label: '子表单',
    }, {
        value: 11,
        label: '链接',
    },{
        value: 6,
        label: '附件',
    },{
        value: 4,
        label: '图片',
    },{
        value: 12,
        label: '富文本',
    } ]
    return arr;
}

// 结算方式
// 结算方式:1=微信,2=支付宝,3=现金,4=pos,5=转账,6=内部调账,7=承兑汇票,8=支票, 9=卖场收款, 10=联盟收款, 11=总部天猫, 12=家装公司待收, 13=银行收款码, 14=其他, 15=微信,支付宝, 16=原路退回微信或支付宝
let settleWays = () => {
    let arr = [{
        value: 1,
        label: '微信',
    }, {
        value: 2,
        label: '支付宝',
    }, {
        value: 3,
        label: '现金',
    }, {
        value: 4,
        label: 'pos',
    }, {
        value: 5,
        label: '转账',
    }, {
        value: 6,
        label: '内部调账',
    }, {
        value: 7,
        label: '承兑汇票',
    }, {
        value: 8,
        label: '支票',
    }, {
        value: 9,
        label: '卖场收款',
    }, {
        value: 10,
        label: '联盟收款',
    }, {
        value: 11,
        label: '总部天猫',
    }, {
        value: 12,
        label: '家装公司待收',
    }, {
        value: 13,
        label: '银行收款码',
    }, {
        value: 14,
        label: '其他',
    }, {
        value: 15,
        label: '微信,支付宝',
    }, {
        value: 16,
        label: '原路退回微信或支付宝',
    }, ]
    return arr;
}

// 目标管理中的考核指标
let goalQuotaType = () => {
    let arr = [{
        value: 1,
        label: '新增客户'
    },
    {
        value: 2,
        label: '新增需求'
    },
    {
        value: 3,
        label: '新增签单'
    },
    {
        value: 4,
        label: '新增收款'
    },
    {
        value: 9,
        label: '新增收款(业绩)'
    },
    {
        value: 13,
        label: '新增下单'
    },
    {
        value: 17,
        label: '新增下单数'
    },
    {
        value: 15,
        label: '新增下单(业绩)'
    },
    {
        value: 5,
        label: '新增人脉'
    },
    {
        value: 6,
        label: '完成测量数'
    },
    {
        value: 7,
        label: '完成方案数'
    },
    {
        value: 8,
        label: '新增合同金额'
    },
    {
        value: 14,
        label: '新增合同金额(业绩)'
    },
    {
        value: 10,
        label: '如约进店客户'
    },
    {
        value: 12,
        label: '新增人脉推荐需求'
    },
    {
        value: 16,
        label: '新增人脉推荐收款'
    },
    {
        value: 18,
        label: '新增人脉推荐进店'
    },
    {
        value: 19,
        label: '新增人脉推荐签单'
    },
    ]
    return arr;
}

// 收款类别
let categoryType = () => {
    let arr = [{
            value: 0,
            label: '其他类'
    },
    {
            value: 1,
            label: '定金类'
    },
    {
            value: 2,
            label: '回款类'
    },
    {
            value: 3,
            label: '全款类'
        }
    ]
    return arr;
}
let loseReason = () =>  {
    let arr = [
        { value: 1, label: '价格原因' },
        { value: 2, label: '产品质量' },
        { value: 3, label: '款式花色' },
        { value: 4, label: '设计方案' },
        { value: 5, label: '服务体验' },
        { value: 6, label: '外界影响' },
        { value: 7, label: '品牌认知' },
        { value: 8, label: '说不清楚' }
    ]
    return arr;
}
let iconArr = () => {
    let arr = [
    {
        name: "[尴尬]",
        emoji: require("@/assets/img/97x97/angry_ios_802.png")
    },
    {
        name: "[发怒]",
        emoji: require("@/assets/img/97x97/awkward_ios_802.png")
    },
    {
        name: "[右哼哼]",
        emoji: require("@/assets/img/97x97/bahr_ios_802.png")
    },
    {
        name: "[勾引]",
        emoji: require("@/assets/img/97x97/beckon_ios_802.png")
    },
    {
        name: "[啤酒]",
        emoji: require("@/assets/img/97x97/beer_ios_802.png")
    },
    {
        name: "[炸弹]",
        emoji: require("@/assets/img/97x97/bomb_ios_802.png")
    },
    {
        name: "[心碎]",
        emoji: require("@/assets/img/97x97/brokenheart_ios_802.png")
    },
    {
        name: "[蛋糕]",
        emoji: require("@/assets/img/97x97/cake_ios_802.png")
    },
    {
        name: "[偷笑]",
        emoji: require("@/assets/img/97x97/chuckle_ios_802.png")
    },
    {
        name: "[鼓掌]",
        emoji: require("@/assets/img/97x97/clap_ios_802.png")
    },
    {
        name: "[菜刀]",
        emoji: require("@/assets/img/97x97/cleaver_ios_802.png")
    },
    {
        name: "[咖啡]",
        emoji: require("@/assets/img/97x97/coffee_ios_802.png")
    },
    {
        name: "[悠闲]",
        emoji: require("@/assets/img/97x97/commando_ios_802.png")
    },
    {
        name: "[得意]",
        emoji: require("@/assets/img/97x97/coolguy_ios_802.png")
    },
    {
        name: "[大哭]",
        emoji: require("@/assets/img/97x97/cry_ios_802.png")
    },
    {
        name: "[奋斗]",
        emoji: require("@/assets/img/97x97/determined_ios_802.png")
    },
    {
        name: "[晕]",
        emoji: require("@/assets/img/97x97/dizzy_ios_802.png")
    },
    {
        name: "[色]",
        emoji: require("@/assets/img/97x97/drool_ios_802.png")
    },
    {
        name: "[困]",
        emoji: require("@/assets/img/97x97/drowsy_ios_802.png")
    },
    {
        name: "[便便]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_poop.png")
    },
    {
        name: "[吐]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_puke.png")
    },
    {
        name: "[玫瑰]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_rose.png")
    },
    {
        name: "[咒骂]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_scold.png")
    },
    {
        name: "[发呆]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_scowl.png")
    },
    {
        name: "[抓狂]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_scream.png")
    },
    {
        name: "[握手]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_shake.png")
    },
    {
        name: "[嘘]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_shhh.png")
    },
    {
        name: "[疑问]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_shocked.png")
    },
    {
        name: "[委屈]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_shrunken.png")
    },
    {
        name: "[害羞]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_shy.png")
    },
    {
        name: "[闭嘴]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_silent.png")
    },
    {
        name: "[骷髅]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_skull.png")
    },
    {
        name: "[睡]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_sleep.png")
    },
    {
        name: "[白眼]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_slight.png")
    },
    {
        name: "[阴险]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_sly.png")
    },
    {
        name: "[微笑]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_smile.png")
    },
    {
        name: "[傲慢]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_smug.png")
    },
    {
        name: "[流泪]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_sob.png")
    },
    {
        name: "[擦汗]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_speechless.png")
    },
    {
        name: "[太阳]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_sun.png")
    },
    {
        name: "[惊讶]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_surprise.png")
    },
    {
        name: "[快哭了]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_tearingup.png")
    },
    {
        name: "[弱]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_thumbsdown.png")
    },
    {
        name: "[强]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_thumbsup.png")
    },
    {
        name: "[衰]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_toasted.png")
    },
    {
        name: "[调皮]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_tongue.png")
    },
    {
        name: "[坏笑]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_trick.png")
    },
    {
        name: "[可怜]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_whimper.png")
    },
    {
        name: "[凋谢]",
        emoji: require("@/assets/img/97x97/emojipedia_wechat_ios_802_wilt.png")
    },
    {
        name: "[拳头]",
        emoji: require("@/assets/img/97x97/fist_ios_802.png")
    },
    {
        name: "[难过]",
        emoji: require("@/assets/img/97x97/frown_ios_802.png")
    },
    {
        name: "[撇嘴]",
        emoji: require("@/assets/img/97x97/grimace_ios_802.png")
    },
    {
        name: "[呲牙]",
        emoji: require("@/assets/img/97x97/grin_ios_802.png")
    },
    {
        name: "[敲打]",
        emoji: require("@/assets/img/97x97/hammer_ios_802.png")
    },
    {
        name: "[爱心]",
        emoji: require("@/assets/img/97x97/heart_ios_802.png")
    },
    {
        name: "[拥抱]",
        emoji: require("@/assets/img/97x97/hug_ios_802.png")
    },
    {
        name: "[愉快]",
        emoji: require("@/assets/img/97x97/joyful_ios_802.png")
    },
    {
        name: "[亲亲]",
        emoji: require("@/assets/img/97x97/kiss_ios_802.png")
    },
    {
        name: "[憨笑]",
        emoji: require("@/assets/img/97x97/laugh_ios_802.png")
    },
    {
        name: "[嘴唇]",
        emoji: require("@/assets/img/97x97/lips_ios_802.png")
    },
    {
        name: "[月亮]",
        emoji: require("@/assets/img/97x97/moon_ios_802.png")
    },
    {
        name: "[抠鼻]",
        emoji: require("@/assets/img/97x97/nosepick_ios_802.png")
    },
    {
        name: "[OK]",
        emoji: require("@/assets/img/97x97/ok_ios_802.png")
    },
    {
        name: "[惊恐]",
        emoji: require("@/assets/img/97x97/panic_ios_802.png")
    },
    {
        name: "[胜利]",
        emoji: require("@/assets/img/97x97/peace_ios_802.png")
    },
    {
        name: "[猪头]",
        emoji: require("@/assets/img/97x97/pig_ios_802.png")
    },
    {
        name: "[鄙视]",
        emoji: require("@/assets/img/97x97/pooh-pooh_ios_802.png")
    },
    {
        name: "[西瓜]",
        emoji: require("@/assets/img/97x97/watermelon_ios_802.png")
        }
    ]
    return arr;
}

let iconOtherArr = () => {
    let arr = [{name:'[玫瑰]',emoji:require('@/assets/img/emoji01.png')},
    {name:'[耶]',emoji:require('@/assets/img/emoji02.png')},
    {name:'[礼物]',emoji:require('@/assets/img/emoji03.png')},
    {name:'[红包]',emoji:require('@/assets/img/emoji04.png')},
    {name:'[福]',emoji:require('@/assets/img/emoji05.png')},
    // {name:'[烟花]',emoji:require('@/assets/img/emoji06.png')},
    {name:'[强]',emoji:require('@/assets/img/emoji07.png')},
    {name:'[嘴唇]',emoji:require('@/assets/img/emoji08.png')},
    {name:'[愉快]',emoji:require('@/assets/img/emoji09.png')},
    // {name:'[666]',emoji:require('@/assets/img/emoji10.png')},
    {name:'[鼓掌]',emoji:require('@/assets/img/emoji11.png')},
    {name:'[加油]',emoji:require('@/assets/img/emoji12.png')},
    {name:'[社会社会]',emoji:require('@/assets/img/emoji13.png')},
    {name:'[哇]',emoji:require('@/assets/img/emoji14.png')},
    {name:'[色]',emoji:require('@/assets/img/emoji15.png')},
    {name:'[亲亲]',emoji:require('@/assets/img/emoji16.png')},
    {name:'[爱心]',emoji:require('@/assets/img/emoji17.png')},]
    return arr;
}

// 播报类型
let broadcastType = () => {
  let arr = [{ id: 1, name: '新增需求' },
    { id: 2, name: '新增签单' },
    { id: 3, name: '新增收款' },
    { id: 4, name: '新增营销云下单' },
    { id: 5, name: '营销云购卡' },
    { id: 6, name: '营销云报名' },
    { id: 7, name: '进店接待播报' },
    { id: 8, name: '当面拜访播报' },
    { id: 9, name: '任务播报' },
    { id: 10, name: '新增推荐线索' },
    { id: 11, name: '新增服务完成' },
    { id: 12, name: '推荐线索更新播报' },
    { id: 13, name: '未按要求跟进播报' },
    { id: 14, name: '合同单播报' },
    { id: 15, name: '新增人脉播报' },
    { id: 16, name: '新增人脉绑定播报' },
    { id: 17, name: '新增人脉推荐需求' },
    { id: 18, name: '新增人脉推荐需求签单' },
    { id: 19, name: '红包任务奖励' },
    { id: 20, name: '指令任务播报' },
    { id: 21, name: '进店邀约成功播报' },
    { id: 22, name: '测量邀约成功播报' },
  ]
  return arr;
}

// 实体门店分类
let storeType=()=>{
    let arr=[{
        value: "1",
        label: "厨源店"
    },
    {
        value: "2",
        label: "5A店"
    },
    {
        value: "3",
        label: "专卖店"
    },
    {
        value: "4",
        label: "乡镇店"
    },
    {
        value: "5",
        label: "KA店"
    },
    {
        value: "6",
        label: "创新渠道店"
    },
    {
        value: "7",
        label: "橱柜商"
    },
    {
        value: "8",
        label: "经销商"
    },
    {
        value: "9",
        label: "直营店"
    },
    {
        value: "11",
        label: "中国新厨房智能生活馆"
    },
    {
        value: "12",
        label: "中国新厨房智能体验馆"
    },
    {
        value: "13",
        label: "中国新厨房智能体验店"
    },
    {
        value: "14",
        label: "新厨房门店（PLUS）"
    },
    {
        value: "15",
        label: "新厨房门店（MINI）"
    },
    {
        value: "16",
        label: "分销网点"
    },
    {
        value: "17",
        label: "社区店"
    },
    {
        value: "21",
        label: "中国新厨房智能生活馆"
    },
    {
        value: "22",
        label: "中国新厨房智能体验馆"
    },
    {
        value: "23",
        label: "中国新厨房智能体验店"
    },
    {
        value: "24",
        label: "新厨房门店（PLUS）"
    },
    {
        value: "25",
        label: "新厨房门店（MINI）"
    },
    {
        value: "26",
        label: "分销网点"
    },
    {
        value: "31",
        label: "中国新厨房智能体验店"
    },
    {
        value: "32",
        label: "中国新厨房门店（PLUS）"
    },
    {
        value: "33",
        label: "中国新厨房门店（MINI）"
    },
    {
        value: "34",
        label: "专区"
    },
    {
        value: "35",
        label: "专柜"
    },
    {
        value: "36",
        label: "分销网点"
    },
    {
        value: "91",
        label: "中国新厨房智能生活馆"
    },
    {
        value: "92",
        label: "中国新厨房智能体验馆"
    },
    {
        value: "93",
        label: "中国新厨房智能体验店"
    },
    {
        value: "94",
        label: "新厨房门店（PLUS）"
    },
    {
        value: "95",
        label: "新厨房门店（MINI）"
    },
    {
        value: "96",
        label: "分销网点"
    }]
    return arr;
}

// 执行人员标签
let tagSetType = ()=>{
    let arr=[{
        value: "1",
        valueStr: "1,其他员工",
        label: "其他员工"
    },
    {
        value: "2",
        valueStr: "2,经销商老板",
        label: "经销商老板"
    },
    {
        value: "3",
        valueStr: "3,店长",
        label: "店长"
    },
    {
        value: "4",
        valueStr: "4,设计师",
        label: "设计师"
    },
    {
        value: "5",
        valueStr: "5,导购",
        label: "导购"
    },
    {
        value: "6",
        valueStr: "6,售后",
        label: "售后"
    },
    {
        value: "7",
        valueStr: "7,职业经理人",
        label: "职业经理人"
    } ]
    return arr;
}

// 投放管理 - 客户分配状态
let distributionFlag = ()=>{
    let arr=[{
        value: "0",
        label: "待分配"
    },
    {
        value: "1",
        label: "(经销商)待分配"
    },
    {
        value: "2",
        label: "已分配"
    },
    {
        value: "3",
        label: "异常线索"
    },
    {
        value: "4",
        label: "分配后删除"
    },
    {
        value: "5",
        label: "分配后退回"
    }]
    return arr;
}

module.exports = {
    customerSource,
    houseType,
    customerLevel,
    followStatus,
    followStreamStatus,
    stayTime,
    saleChanceStatus,
    saleChanceStatusNew,
    followType,
    fixStage,
    lostReason,
    followModel,
    allocateType,
    customerTypeText,
    customerType,
    distributeType,
    getLabelArr,
    getLabel,
    starRating,
    demandLevel,
    getStatusText,
    sellChangeLevel,
    formatChannel,
    formatChooseChannel,
    formatSellStage,
    formatSetSellStage,
    formatServiceType,
    activityLabel,
    decorationStyle,
    intentionLevel,
    demandProgressLevel,
    sellChangeStage,
    customerInfo,
    followInfo,
    columnType,
    columnServiceType,
    demandInfo,
    settleWays,
    goalQuotaType,
    categoryType,
    broadcastType,
    loseReason,
    iconArr,
    iconOtherArr,
    storeType,
    tagSetType,
    distributionFlag
}
