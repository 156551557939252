import Vue from 'vue';
import VueRouter from 'vue-router';
import face from '@/layout/face.vue';
import account from '@/layout/account.vue';
import { constantRoutes } from './main' // 不符合菜单layout的路由

Vue.use(VueRouter);

let routes = [{
        path: '/pc/index',
        name: '',
        component: face,
        children: [{
                path: '/pc/index',
                component: () =>
                    import ('@/views/index/index.vue'),
            },
            {
                path: '/pc/auditList',
                name: '审核代办',
                component: () =>
                    import ('@/views/audit/auditList.vue'),
            },
            {
                path: '/pc/contractAudit',
                name: '合同单审核',
                component: () =>
                    import ('@/views/audit/contractAudit.vue'),
            },
            {
                path: '/pc/customerList',
                name: '客户列表',
                component: () =>
                    import ('@/views/customer/customerList.vue'),
            },
            {
                path: '/pc/demandList',
                name: '需求列表',
                component: () =>
                    import ('@/views/customer/demandList.vue'),
            },
						{
                path: '/pc/signList',
                name: '签单列表',
                component: () =>
                    import ('@/views/customer/signList.vue'),
            },
            {
                path: '/pc/introduceControl',
                name: '人脉管理',
                component: () =>
                    import ('@/views/introduce/introduceControl.vue'),
            },
            {
                path: '/pc/customerDetail',
                name: '客户详情',
                component: () =>
                    import ('@/views/customer/customerDetail.vue'),
            },
            {
                path: '/pc/goalControl',
                name: '目标管理',
                component: () =>
                    import ('@/views/goal/goalControl.vue'),
            },
            {
                path: '/pc/teamSetting',
                component: () =>
                    import ('@/views/setting/teamSetting.vue'),
            },
            {
                path: '/pc/roleSetting',
                component: () =>
                    import ('@/views/setting/roleSetting.vue'),
            },
            {
                path: '/pc/jxcRoleSetting',
                name: '进销存角色权限',
                component: () =>
                    import ('@/views/setting/jxcRoleSetting.vue'),
            },
            {
                path: '/pc/systemSetting',
                component: () =>
                    import ('@/views/setting/systemSetting.vue'),
            },
            {
                path: '/pc/pay',
                component: () =>
                    import ('@/views/pay/pay.vue'),
            },
            {
                path: '/pc/agreement',
                component: () =>
                    import ('@/views/agreement/agreement.vue'),
            },
            {
                path: '/pc/succession',
                name: '离职继承',
                component: () =>
                    import ('@/views/setting/succession.vue'),
            },
            {
                path: '/pc/successionDetail',
                name: '离职继承详情',
                component: () =>
                    import ('@/views/setting/successionDetail.vue'),
            },
            {
                path: '/pc/contractList',
                name: '合同单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/contract/contractList.vue'),
            },
            {
                path: '/pc/contractAdd',
                name: '新增合同单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/contract/contractAdd.vue'),
            },
            {
                path: '/pc/procurement',
                name: '请购单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/procurement/procurement.vue'),
            },
            {
                path: '/pc/procurementAdd',
                name: '新增请购单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/procurement/procurementAdd.vue'),
            },
            {
                path: '/pc/procurementDetail',
                name: '请购单详情',
                component: () =>
                    import ('@/views/PurchaseSaleStock/procurement/procurementDetail.vue'),
            },
            {
                path: '/pc/purchaseOrders',
                name: '采购单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/purchaseOrders/purchaseOrders.vue'),
            },
            {
                path: '/pc/purchaseOrdersAdd',
                name: '新增采购单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/purchaseOrders/purchaseOrdersAdd.vue'),
            },
            {
                path: '/pc/productBasicMessage',
                name: '产品基础信息',
                component: () =>
                    import ('@/views/basicDataManagement/productBasicMessage.vue'),
            },
            {
                path: '/pc/supplierInformation',
                name: '供应商信息',
                component: () =>
                    import ('@/views/basicDataManagement/supplierInformation.vue'),
            },
            {
                path: '/pc/brandInformation',
                name: '品牌信息',
                component: () =>
                    import ('@/views/basicDataManagement/brandInformation.vue'),
            },
            {
                path: '/pc/warehouseName',
                name: '仓库名称',
                component: () =>
                    import ('@/views/basicDataManagement/warehouseName.vue'),
            },
            {
                path: '/pc/stockIn',
                name: '入库单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/warehouse/stockIn.vue'),
            },
            {
                path: '/pc/stockInAdd',
                name: '新增入库单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/warehouse/stockInAdd.vue'),
            },
            {
                path: '/pc/otherStockIn',
                name: '其他入库单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/other/otherStockIn.vue'),
            },
            {
                path: '/pc/otherStockInAdd',
                name: '新增其他入库单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/other/otherStockInAdd.vue'),
            },
            {
                path: '/pc/putOut',
                name: '出库单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/warehouse/putOut.vue'),
            },
            {
                path: '/pc/putOutAdd',
                name: '出库单添加',
                component: () =>
                    import ('@/views/PurchaseSaleStock/warehouse/putOutAdd.vue'),
            },
            {
                path: '/pc/putOutEdit',
                name: '出库单编辑',
                component: () =>
                    import ('@/views/PurchaseSaleStock/warehouse/putOutEdit.vue'),
            },
            {
                path: '/pc/putOutDetail',
                name: '出库单详情',
                component: () =>
                    import ('@/views/PurchaseSaleStock/warehouse/putOutDetail.vue'),
            },
            {
                path: '/pc/warehouse',
                name: '库存表',
                component: () =>
                    import ('@/views/PurchaseSaleStock/warehouse/warehouse.vue'),
            },
            {
                path: '/pc/customerProfit',
                name: '客户利润表',
                component: () =>
                    import ('@/views/PurchaseSaleStock/table/customerProfit.vue'),
            },
            {
                path: '/pc/extraordinary',
                name: '商品收发明细表',
                component: () =>
                    import ('@/views/PurchaseSaleStock/table/extraordinary.vue'),
            },
            {
                path: '/pc/summarySheet',
                name: '商品收发汇总表',
                component: () =>
                    import ('@/views/PurchaseSaleStock/table/summarySheet.vue'),
            },
            {
                path: '/pc/otherPutOut',
                name: '其他出库单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/other/putOut.vue'),
            },
            {
                path: '/pc/otherPutOutAdd',
                name: '其他出库单添加',
                component: () =>
                    import ('@/views/PurchaseSaleStock/other/putOutAdd.vue'),
            },
            {
                path: '/pc/otherPutOutEdit',
                name: '其他出库单编辑',
                component: () =>
                    import ('@/views/PurchaseSaleStock/other/putOutEdit.vue'),
            },
            {
                path: '/pc/otherPutOutDetail',
                name: '其他出库单详情',
                component: () =>
                    import ('@/views/PurchaseSaleStock/other/putOutDetail.vue'),
            },
            {
                path: '/pc/transferOrder',
                name: '调拨单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/transferOrder/transferOrder.vue'),
            },
            {
                path: '/pc/transferOrderAdd',
                name: '新增调拨单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/transferOrder/transferOrderAdd.vue'),
            },
            {
                path: '/pc/inventoryList',
                name: '盘点单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/inventoryList/inventoryList.vue'),
            },
            {
                path: '/pc/inventoryListAdd',
                name: '新增盘点单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/inventoryList/inventoryListAdd.vue'),
            },
            {
                path: '/pc/reject',
                name: '退货单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/reject/reject.vue'),
            },
            {
                path: '/pc/rejectAdd',
                name: '新增退货单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/reject/rejectAdd.vue'),
            },
            {
                path: '/pc/rejectEdit',
                name: '编辑退货单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/reject/rejectEdit.vue'),
            },
            {
                path: '/pc/rejectDetail',
                name: '退货单详情',
                component: () =>
                    import ('@/views/PurchaseSaleStock/reject/rejectDetail.vue'),
            },
            {
                path: '/pc/goodsPurchase',
                name: '已请代采报表详情',
                component: () =>
                    import ('@/views/PurchaseSaleStock/table/goodsPurchase.vue'),
            },
            {
                path: '/pc/soldButNotSold',
                name: '已销未出报表',
                component: () =>
                    import ('@/views/PurchaseSaleStock/table/soldButNotSold.vue'),
            },
            {
                path: '/pc/purchasedButNotPurchased',
                name: '已采未入报表',
                component: () =>
                    import ('@/views/PurchaseSaleStock/table/purchasedButNotPurchased.vue'),
            },
            {
                path: '/pc/distributionOrders',
                name: '配货单',
                component: () =>
                    import ('@/views/PurchaseSaleStock/distributionOrders/distributionOrders.vue'),
            },
            {
              path: '/pc/purchaseReturn',
              name: '采购退货单',
              component: () =>
                  import ('@/views/PurchaseSaleStock/purchaseReturn/purchaseReturn.vue'),
            },
            {
              path: '/pc/inventoryGainDoc',
              name: '盘盈单',
              component: () =>
                  import ('@/views/PurchaseSaleStock/inventoryGainDoc/inventoryGainDoc.vue'),
            },
            {
              path: '/pc/inventoryLossSheet',
              name: '盘亏单',
              component: () =>
                  import ('@/views/PurchaseSaleStock/inventoryLossSheet/inventoryLossSheet.vue'),
            },
            {
              path: '/pc/consignmentDeliveryDoc',
              name: '委托代销出库单',
              component: () =>
                  import ('@/views/PurchaseSaleStock/consignment/consignmentDeliveryDoc.vue'),
            },
            {
              path: '/pc/consignmentReturnDoc',
              name: '委托代销退货单',
              component: () =>
                  import ('@/views/PurchaseSaleStock/consignment/consignmentReturnDoc.vue'),
            },
            {
              path: '/pc/consignmentReceiptDoc',
              name: '受托代销入库单',
              component: () =>
                  import ('@/views/PurchaseSaleStock/consignment/consignmentReceiptDoc.vue'),
            },
            {
              path: '/pc/consignedSalesReturnDoc',
              name: '受托代销退货单',
              component: () =>
                  import ('@/views/PurchaseSaleStock/consignment/consignedSalesReturnDoc.vue'),
            },
            {
              path: '/pc/consignmentTable',
              name: '委托代销库存表',
              component: () =>
                  import ('@/views/PurchaseSaleStock/table/consignmentTable.vue'),
            },
            {
              path: '/pc/consignmentDetails',
              name: '委托代销收发明细表',
              component: () =>
                  import ('@/views/PurchaseSaleStock/table/consignmentDetails.vue'),
            },
            {
              path: '/pc/consignedTable',
              name: '受托代销库存表',
              component: () =>
                  import ('@/views/PurchaseSaleStock/table/consignedTable.vue'),
            },
            {
              path: '/pc/consignedDetails',
              name: '受托代销收发明细表',
              component: () =>
                  import ('@/views/PurchaseSaleStock/table/consignedDetails.vue'),
            },
            {
              path: '/pc/inventoryGainDocAdd',
              name: '盘盈单详情',
              component: () =>
                  import ('@/views/PurchaseSaleStock/inventoryGainDoc/inventoryGainDocAdd.vue'),
            },
            {
              path: '/pc/inventoryLossSheetAdd',
              name: '盘亏单详情',
              component: () =>
                  import ('@/views/PurchaseSaleStock/inventoryLossSheet/inventoryLossSheetAdd.vue'),
            },
            {
              path: '/pc/purchaseReturnAdd',
              name: '采购退货单详情',
              component: () =>
                  import ('@/views/PurchaseSaleStock/purchaseReturn/purchaseReturnAdd.vue'),
            },
            {
              path: '/pc/consignmentDeliveryDocAdd',
              name: '委托代销出库单详情',
              component: () =>
                  import ('@/views/PurchaseSaleStock/consignment/consignmentDeliveryDocAdd.vue'),
            },
            {
              path: '/pc/consignmentReturnDocAdd',
              name: '委托代销退货单详情',
              component: () =>
                  import ('@/views/PurchaseSaleStock/consignment/consignmentReturnDocAdd.vue'),
            },
            {
              path: '/pc/consignmentReceiptDocAdd',
              name: '受托代销入库单详情',
              component: () =>
                  import ('@/views/PurchaseSaleStock/consignment/consignmentReceiptDocAdd.vue'),
            },
            {
              path: '/pc/consignedSalesReturnDocAdd',
              name: '受托代销退货单详情',
              component: () =>
                  import ('@/views/PurchaseSaleStock/consignment/consignedSalesReturnDocAdd.vue'),
            },
            {
                path: '/pc/scriptLibrary',
                name: '话术库',
                component: () =>
                    import ('@/views/marketing/scriptLibrary.vue'),
            },
            {
                path: '/pc/materialLibrary',
                name: '素材库',
                component: () =>
                    import ('@/views/marketing/materialLibrary.vue'),
            },
            {
                path: '/pc/materialDetail',
                name: '雷达素材库详情',
                component: () =>
                    import ('@/views/marketing/materialDetail.vue'),
            },
            {
                path: '/pc/materialFormDataList',
                name: '统计数据',
                component: () =>
                    import ('@/views/marketing/materialFormDataList.vue'),
            },
            {
                path: '/pc/xiaoquList',
                name: '小区列表',
                component: () =>
                    import ('@/views/xiaoquMap/xiaoquList.vue'),
            },
            {
                path: '/pc/Audit',
                name: '小区楼栋审核',
                component: () =>
                    import ('@/views/xiaoquMap/audit.vue'),
            },
            {
                path: '/pc/xiaoquAudit',
                name: '小区审核',
                component: () =>
                    import ('@/views/xiaoquMap/xiaoquAudit.vue'),
            },
            {
                path: '/pc/buildingDetail',
                name: '小区详情',
                component: () =>
                    import ('@/views/xiaoquMap/buildingDetail.vue'),
            },
            {
                path: '/pc/floorPlan',
                name: '小区户型图',
                component: () =>
                    import ('@/views/xiaoquMap/floorPlan.vue'),
            },
            {
                path: '/pc/houseDetail',
                name: '楼栋详情',
                component: () =>
                    import ('@/views/xiaoquMap/houseDetail.vue'),
            },
            {
                path: '/pc/nuimenStorePage',
                name: '数字门店',
                component: () =>
                    import ('@/views/nuimenStore/nuimenStorePage.vue'),
            },
            {
                path: '/pc/deviceList',
                name: '设备列表',
                component: () =>
                    import ('@/views/nuimenStore/deviceList.vue'),
            },
            {
                path: '/pc/seaList',
                name: '客户公海',
                component: () =>
                    import ('@/views/customer/seaList.vue'),
            },
            {
                path: '/pc/customerAssociated',
                name: '客户联查表',
                component: () =>
                    import ('@/views/customer/customerAssociated.vue'),
            },
            {
                path: '/pc/operationLog',
                name: '操作日志',
                component: () =>
                    import ('@/views/xiaoquMap/operationLog.vue'),
            },
            {
                path: '/pc/customerBase',
                name: '客户群',
                component: () =>
                    import ('@/views/customer/customerBase.vue'),
            },
            {
                path: '/pc/customerBaseSOP',
                name: '群SOP',
                component: () =>
                    import ('@/views/customer/customerBaseSOP.vue'),
            },
            {
                path: '/pc/installCode',
                name: '营销云',
                component: () =>
                    import ('@/views/pay/installCode.vue'),
            },
            {
                path: '/pc/customerBaseSOPDetail',
                name: '新增客户SOP',
                component: () =>
                    import ('@/views/customer/customerBaseSOPDetail.vue'),
            },
            {
                path: '/pc/customerGroupBaseSOPDetail',
                name: '新增群SOP',
                component: () =>
                    import ('@/views/customer/customerGroupBaseSOPDetail.vue'),
            },
            {
                path: '/pc/customerBaseSOPData',
                name: '客户SOP数据',
                component: () =>
                    import ('@/views/customer/customerBaseSOPData.vue'),
            },
            {
                path: '/pc/customerGroupBaseSOPData',
                name: '群SOP数据',
                component: () =>
                    import ('@/views/customer/customerGroupBaseSOPData.vue'),
            },
            {
                path: '/pc/service',
                name: '服务管理',
                component: () =>
                    import ('@/views/service/serviceList.vue'),
            },
            {
                path: '/pc/reportManagement',
                name: '工作汇报',
                component: () =>
                    import ('@/views/goal/reportManagement.vue'),
            },
            {
                path: '/pc/massSending',
                name: '群发管理',
                component: () =>
                    import ('@/views/operation/massSending.vue'),
            },
            {
                path: '/pc/massSendingDetail',
                name: '群发详情',
                component: () =>
                    import ('@/views/operation/massSendingDetail.vue'),
            },
            {
                path: '/pc/massSendingAdd',
                name: '新建客户群发',
                component: () =>
                    import ('@/views/operation/massSendingAdd.vue'),
            },
            {
                path: '/pc/codeList',
                component: () =>
                    import ('@/views/code/codeList.vue'),
            },
            {
                path: '/pc/codeDetail',
                component: () =>
                    import ('@/views/code/codeDetail.vue'),
            },
            {
                path: '/pc/codeChart',
                component: () =>
                    import ('@/views/code/codeChart.vue'),
            },
            {
                path: '/pc/codeForm',
                component: () =>
                    import ('@/views/code/codeForm.vue'),
            },
            {
                path: '/pc/customerWelcome',
                name: '欢迎语管理',
                component: () =>
                    import ('@/views/operation/customerWelcome.vue'),
            },
            {
                path: '/pc/customerWelcomeAdd',
                name: '新建欢迎语',
                component: () =>
                    import ('@/views/operation/customerWelcomeAdd.vue'),
            },
            {
                path: '/pc/streamORG',
                component: () =>
                    import ('@/views/stream/streamORG.vue'),
            },
						{
                path: '/pc/approvalForm',
                name: '下游审批单',
                component: () =>
                    import ('@/views/stream/ApprovalForm.vue'),
            },
            {
                path: '/pc/streamMaterial',
                name: '上下游内容管理',
                component: () =>
                    import ('@/views/stream/streamMaterial.vue'),
            },
            {
                path: '/pc/streamMaterialDetail',
                name: '上下游内容管理详情',
                component: () =>
                    import ('@/views/stream/streamMaterialDetail.vue'),
            },
            {
                path: '/pc/streamMaterialTags',
                name: '内容管理',
                component: () =>
                    import ('@/views/stream/streamMaterialTags.vue'),
            },
            {
                path: '/pc/salarySchemeManagement',
                name: '薪酬方案管理',
                component: () =>
                    import ('@/views/salaryManage/salarySchemeManage.vue'),
            },
            {
                path: '/pc/salarySetPenal',
                name: '设置奖罚',
                component: () =>
                    import ('@/views/salaryManage/salarySetPenal.vue'),
            },
            {
                path: '/pc/salaryTeamManagement',
                name: '薪酬团队管理',
                component: () =>
                    import ('@/views/salaryManage/salaryTeamManagement.vue'),
            },
            {
                path: '/pc/salaryReportManagement',
                name: '薪酬报表管理',
                component: () =>
                    import ('@/views/salaryManage/salaryReportManagement.vue'),
            },
            {
                path: '/pc/postSetting',
                component: () =>
                    import ('@/views/setting/postSetting.vue'),
            },
            {
                path: '/pc/introduceClue',
                name: '人脉线索管理',
                component: () =>
                    import ('@/views/introduce/introduceClue.vue'),
            },
            {
                path: '/pc/introduceCompany',
                name: '人脉公司管理',
                component: () =>
                    import ('@/views/introduce/introduceCompany.vue'),
            },
            {
                path: '/pc/introduceCommission',
                name: '人脉推荐需求分佣',
                component: () =>
                    import ('@/views/introduce/introduceCommission.vue'),
            },
            {
                path: '/pc/digitalWareScreen',
                name: '数据大屏',
                component: () =>
                    import ('@/views/dataBoard/digitalWareScreen.vue'),
            },
            {
                path: '/pc/dataBoard',
                name: '数据看板',
                component: () =>
                    import ('@/views/dataBoard/dataBoardList.vue'),
            },
            {
                path: '/pc/streamCustomerBaseSOP',
                name: '运营管理',
                component: () =>
                    import ('@/views/stream/streamCustomerBaseSOP.vue'),
            },
            {
                path: '/pc/streamCustomerBaseSOPDetail',
                name: '客户SOP',
                component: () =>
                    import ('@/views/stream/streamCustomerBaseSOPDetail.vue'),
            },
            {
                path: '/pc/streamFormDataList',
                name: '上下游统计数据',
                component: () =>
                    import ('@/views/marketing/streamFormDataList.vue'),
            },
            {
                path: '/pc/customerBaseSOPDetailTemplate',
                name: 'SOP管理',
                component: () =>
                    import ('@/views/customer/customerBaseSOPDetailTemplate.vue'),
            },
            {
                path: '/pc/taskManagement',
                name: '任务管理',
                component: () =>
                    import ('@/views/operation/taskManagement.vue'),
            },
            {
                path: '/pc/collectionDetails',
                component: () =>
                    import ('@/views/customer/collectionDetails.vue'),
            },
            {
                path: '/pc/streamDelivery',
                name: '投放活动',
                component: () =>
                    import ('@/views/stream/streamDelivery.vue'),
            },
            {
                path: '/pc/channelManage',
                name: '投放渠道列表',
                component: () =>
                    import ('@/views/stream/channelManage.vue'),
            },
            {
                path: '/pc/channelConfiguration',
                name: '投放渠道设置',
                component: () =>
                    import ('@/views/stream/channelConfiguration.vue'),
            },
            {
                path: '/pc/streamChartDetail',
                name: '投放活动数据',
                component: () =>
                    import ('@/views/stream/streamChartDetail.vue'),
            },
            {
                path: '/pc/taskManageDetail',
                name: '自建任务详情',
                component: () =>
                    import ('@/views/operation/taskManageDetail.vue'),
            },
            {
                path: '/pc/introduceAnalysis',
                name: '人脉分析',
                component: () =>
                    import ('@/views/introduce/introduceAnalysis.vue'),
            },
						{
                path: '/pc/introduceRecommendInShop',
                name: '人脉推荐及的难点分析',
                component: () =>
                    import ('@/views/introduce/IntroduceRecommendInShop.vue'),
            },
            {
                path: '/pc/dealerAnalysis',
                name: '人脉分析总部',
                component: () =>
                    import ('@/views/stream/dealerAnalysis.vue'),
            },
            {
                path: '/pc/streamTaskMage',
                name: '上下游任务管理',
                component: () =>
                    import ('@/views/stream/streamTaskMage.vue'),
            },
            {
                path: '/pc/streamTaskMageDetail',
                name: '上下游部门报表',
                component: () =>
                    import ('@/views/stream/streamTaskMageDetail.vue'),
            },
            {
                path: '/pc/operateDelivery',
                name: '投放管理（经销商）',
                component: () =>
                    import ('@/views/operation/operateDelivery.vue'),
            },
            {
                path: '/pc/operationChannelManage',
                name: '渠道配置',
                component: () =>
                    import ('@/views/operation/operationChannelManage.vue'),
            },
            {
                path: '/pc/operationChannelConfiguration',
                name: '渠道配置详情',
                component: () =>
                    import ('@/views/operation/operationChannelConfiguration.vue'),
            },
            {
                path: '/pc/storeSetting',
                component: () =>
                    import ('@/views/setting/storeSetting.vue'),
            },
            {
                path: '/pc/usageAnalyse',
                component: () =>
                    import ('@/views/dataBoard/usageAnalyse.vue'),
            },
            {
                path: '/pc/intelligentVoice',
                name: '智能外呼',
                component: () =>
                    import ('@/views/smartCall/intelligentVoice.vue'),
            },
            {
                path: '/pc/rechargeRecord',
                name: '充值中心',
                component: () =>
                    import ('@/views/smartCall/rechargeRecord.vue'),
            },
            {
                path: '/pc/intelligentVoiceDetail',
                name: '外呼详情',
                component: () =>
                    import ('@/views/smartCall/intelligentVoiceDetail.vue'),
            },
            {
                path: '/pc/payPage',
                name: '支付',
                component: () =>
                    import ('@/views/smartCall/payPage.vue'),
            },
            {
                path: '/pc/billsDetail',
                name: '账单详情',
                component: () =>
                    import ('@/views/smartCall/billsDetail.vue'),
            },
            {
              path: '/pc/intelligentVoiceNew',
              name: '智能外呼',
              component: () =>
                  import ('@/views/smartCall/intelligentVoiceNew.vue'),
            },
            {
              path: '/pc/rechargeRecordNew',
              name: '充值中心',
              component: () =>
                  import ('@/views/smartCall/rechargeRecordNew.vue'),
            },
            {
              path: '/pc/intelligentVoiceNewDetail',
              name: '外呼详情',
              component: () =>
                  import ('@/views/smartCall/intelligentVoiceNewDetail.vue'),
            },
            {
              path: '/pc/billsDetailNew',
              name: '账单详情',
              component: () =>
                  import ('@/views/smartCall/billsDetailNew.vue'),
            },
            {
              path: '/pc/payPageNew',
              name: '支付',
              component: () =>
                  import ('@/views/smartCall/payPageNew.vue'),
            },
            {
                path: '/pc/riskLog',
                component: () =>
                    import ('@/views/riskControl/riskLog.vue'),
            }, {
                path: '/pc/baseCustomerDetail',
                name: '投放管理客户详情',
                component: () =>
                    import ('@/views/stream/streamCustomerDetail.vue'),
            },
            {
                path: '/pc/streamCustomerDetail',
                name: '上下游投放管理客户详情',
                component: () =>
                    import ('@/views/stream/streamCustomerDetail.vue'),
            },
            {
                path: '/pc/reportData',
                name: '汇报数据',
                component: () =>
                    import ('@/views/goal/reportData.vue'),
            },
            {
                path: '/pc/streamUsageAnalyse',
                component: () =>
                    import ('@/views/stream/streamUsageAnalyse.vue'),
            },
            {
                path: '/pc/dataIndicator',
                name: '数据指标统计',
                component: () =>
                    import ('@/views/dataBoard/dataIndicator.vue'),
            },
            {
                path: '/pc/pyqSendingDetail',
                component: () =>
                    import ('@/views/stream/pyqSendingDetail.vue'),
            },
            {
                path: '/pc/pyqSendingForm',
                component: () =>
                    import ('@/views/stream/pyqSendingForm.vue'),
            },
            {
                path: '/pc/redPacketSet',
                component: () =>
                    import ('@/views/stream/redPacketSet.vue'),
            },
            {
                path: '/pc/streamTaskData',
                component: () =>
                    import ('@/views/stream/streamTaskData.vue'),
            },
            {
                path: '/pc/friendSending',
                component: () =>
                    import ('@/views/operation/friendSending.vue'),
            },
            {
                path: '/pc/redPacketExamine',
                component: () =>
                    import ('@/views/stream/redPacketExamine.vue'),
            },
            {
                path: '/pc/approvalIntoShop',
                component: () =>
                    import ('@/views/customer/approvalIntoShop.vue'),
            },
						{
                path: '/pc/visitRegistration',
                component: () =>
                    import ('@/views/visit/VisitRegistration.vue'),
            },
            {
                path: '/pc/cusFollowUpList',
                name: '客户跟进统计',
                component: () =>
                    import ('@/views/dataBoard/cusFollowUpList.vue'),
            },
						{
                path: '/pc/activityDataCount',
                name: '活动数据统计',
                component: () =>
                    import ('@/views/dataBoard/activityDataCount.vue'),
            },
            {
                path: '/pc/intelligenceAlert',
                component: () =>
                  import ('@/views/customer/intelligenceAlert.vue'),
            },
            {
                path: '/pc/redPacketData',
                component: () =>
                    import ('@/views/stream/redPacketData.vue'),
            },
            {
                path: '/pc/serviceTable',
                name: '已服务报表',
                component: () =>
                    import ('@/views/service/serviceTable.vue'),
            },
            // {
            //     path: '/pc/downStreamDataOrigin',
            //     name: '查看下游数据',
            //     component: () =>
            //         import ('@/views/stream/downStreamDataOrigin.vue'),
            // },
            {
                path: '/pc/downStreamData',
                name: '查看下游数据',
                component: () =>
                    import ('@/views/stream/downStreamIndex.vue'),
            },
            {
                path: '/pc/serviceDetail',
                name: '服务单详情',
                component: () =>
                    import ('@/views/service/serviceDetail.vue'),
            },
            {
                path: '/pc/taskFinishData',
                name: '完成情况',
                component: () =>
                    import ('@/views/commandTask/taskFinishData.vue'),
            },
            {
                path: '/pc/taskPackageIndex',
                name: '任务包',
                component: () =>
                    import ('@/views/operation/taskPackageIndex.vue'),
            },
            {
                path: '/pc/taskPackageSet',
                name: '任务包',
                component: () =>
                    import ('@/views/operation/taskPackageSet.vue'),
            },
            {
                path: '/pc/taskPackageData',
                name: '任务包数据',
                component: () =>
                    import ('@/views/operation/taskPackageData.vue'),
            },
            {
                path: '/pc/placeInfo',
                name: '投放概况',
                component: () =>
                    import ('@/views/throw/placeInfo.vue')
            },
            {
                path: '/pc/dataAnalysis',
                name: '数据分析',
                component: () =>
                    import ('@/views/throw/dataAnalysis.vue')
            },
            {
                path: '/pc/clueControl',
                name: '线索管理',
                component: () =>
                    import ('@/views/throw/clueControl.vue'),
            },
            {
                path: '/pc/pushClueSet',
                name: '入库规则配置',
                component: () =>
                    import ('@/views/throw/pushClueSet.vue'),
            },
            {
                path: '/pc/distributeClueSet',
                name: '分发规则配置',
                component: () =>
                    import ('@/views/throw/distributeClueSet.vue'),
            },
            {
                path: '/pc/channelManagement',
                name: '渠道管理',
                component: () =>
                    import ('@/views/channel/channelManagement.vue'),
            },
            {
              path: '/pc/labelCenter',
              name: '标签管理',
              component: () =>
                  import ('@/views/labelCenter/index.vue')
            },
            {
                path: '/pc/clueCleaning',
                name: '清洗规则配置',
                component: () =>
                    import ('@/views/channel/clueCleaning.vue'),
            },
            {
                path: '/pc/addNewClue',
                name: '新建清洗规则',
                component: () =>
                    import ('@/views/channel/addNewClue.vue'),
            },
            {
                path: '/pc/clueBillsDetail',
                name: '任务账单详情',
                component: () =>
                    import ('@/views/channel/clueBillsDetail.vue'),
            },
        ]
    },
    {
        path: '/pc/materialFormDetail',
        name: '表单添加内容',
        component: () =>
            import ('@/views/marketing/materialFormDetail.vue'),
    },
    {
        path: '/pc/streamMaterialFormDetail',
        name: '上下游表单添加内容',
        component: () =>
            import ('@/views/stream/streamMaterialFormDetail.vue'),
    },
    {
        path: '/pc/video',
        component: () =>
            import ('@/views/marketing/video.vue'),
    },
    {
        path: '/pc/serviceProcess',
        name: '服务流程设置',
        component: () =>
            import ('@/views/setting/serviceProcess.vue'),
    },
    {
        path: '/pc/reportDetail',
        name: '汇报详情',
        component: () =>
            import ('@/views/goal/reportDetail.vue'),
    },
    {
        path: '/pc/salarySchemeDetail',
        name: '薪酬方案详情',
        component: () =>
            import ('@/views/salaryManage/salarySchemeDetail.vue'),
    },
    {
        path: '/pc/financeIndex',
        name: '欢客云会计',
        component: account,
        children: [{
                path: '/pc/financeIndex',
                name: '云会计首页',
                meta: { keepAlive: false },
                component: () =>
                    import ('@/views/index/financeIndex.vue')
            },
            {
                path: '/pc/salesNanagement',
                name: '',
                meta: { keepAlive: false },
                component: () =>
                    import ('@/views/financial/salesNanagement/salesNanagement.vue')
            },
            {
                path: '/pc/addCertificate',
                name: '录凭证',
                meta: { keepAlive: true },
                component: () =>
                    import ('@/views/financial/certificate/addCertificate.vue')
            },
            {
                path: '/pc/certificateList',
                name: '查凭证',
                meta: { keepAlive: false },
                component: () =>
                    import ('@/views/financial/certificate/certificateList.vue')
            },
            {
                path: '/pc/certificateDetail',
                name: '凭证详情',
                meta: { keepAlive: false },
                component: () =>
                    import ('@/views/financial/certificate/certificateDetail.vue')
            },
            {
                path: '/pc/certificateGuide',
                name: '业务凭证模板',
                meta: { keepAlive: false },
                component: () =>
                    import ('@/views/financial/certificate/certificateGuide.vue')
            },
            {
                path: '/pc/subjectList',
                name: '科目',
                component: () =>
                    import ('@/views/financial/subject/subjectList.vue')
            },
            {
                path: '/pc/bankStatement',
                name: '供应商对账单',
                component: () =>
                    import ('@/views/financial/bankStatement/bankStatement.vue')
            },
            {
                path: '/pc/paymentOrderList',
                name: '付款单',
                meta: { keepAlive: false },
                component: () =>
                    import ('@/views/financial/paymentOrder/paymentOrderList.vue')
            },
            {
                path: '/pc/addPaymentOrder',
                name: '新增付款单',
                meta: { keepAlive: false },
                component: () =>
                    import ('@/views/financial/paymentOrder/addPaymentOrder.vue')
            },
            {
                path: '/pc/paymentOrderDetail',
                name: '新增付款单详情',
                meta: { keepAlive: false },
                component: () =>
                    import ('@/views/financial/paymentOrder/paymentOrderDetail.vue')
            },
            {
                path: '/pc/customerStatement',
                name: '卖场对账单',
                component: () =>
                    import ('@/views/financial/customerStatement/customerStatement.vue')
            },
            {
                path: '/pc/accountManagement',
                name: '账户管理',
                component: () =>
                    import ('@/views/financial/accountManagement/accountManagement.vue')
            },
            {
                path: '/pc/collectionUnit',
                name: '收款单位管理',
                component: () =>
                    import ('@/views/financial/accountManagement/collectionUnit.vue')
            },
            {
                path: '/pc/subjectAssociationSetting',
                name: '科目关联设置',
                component: () =>
                    import ('@/views/financial/subjectAssociationSetting/subjectAssociationSetting.vue')
            },
            {
                path: '/pc/subjectMyAccount',
                name: '科目关联设置-账户管理',
                component: () =>
                    import ('@/views/financial/subjectAssociationSetting/subjectMyAccount.vue')
            },
            {
                path: '/pc/supplierManagement',
                name: '科目关联设置-供应商管理',
                component: () =>
                    import ('@/views/financial/subjectAssociationSetting/supplierManagement.vue')
            },
            {
                path: '/pc/collectionUnitSetting',
                name: '科目关联设置-收款单位管理',
                component: () =>
                    import ('@/views/financial/subjectAssociationSetting/collectionUnitSetting.vue')
            },
            {
                path: '/pc/settlementMethod',
                name: '结算方式',
                component: () =>
                    import ('@/views/financial/settlementMethod/settlementMethod.vue')
            },
            {
                path: '/pc/collectionOrder',
                name: '收款单',
                component: () =>
                    import ('@/views/financial/collectionOrder/collectionOrder.vue')
            },
            {
                path: '/pc/addCollectOrder',
                name: '收款源单',
                component: () =>
                    import ('@/views/financial/collectionOrder/addCollectOrder.vue')
            },
            {
                path: '/pc/collectionOrderDetail',
                name: '收款单详情',
                component: () =>
                    import ('@/views/financial/collectionOrder/collectionOrderDetail.vue')
            },
            {
                path: '/pc/voucherTemplate',
                name: '凭证模板',
                component: () =>
                    import ('@/views/financial/voucherTemplate/voucherTemplate.vue')
            },
            {
                path: '/pc/certificateGuideDetail',
                name: '业务凭证模板详情',
                component: () =>
                    import ('@/views/financial/certificate/certificateGuideDetail.vue')
            },
            {
                path: '/pc/certificateGuideAdd',
                name: '新增业务凭证模板',
                component: () =>
                    import ('@/views/financial/certificate/certificateGuideAdd.vue')
            },
            {
                path: '/pc/caiWuInitialData',
                name: '财务初始余额',
                component: () =>
                    import ('@/views/financial/initialData/caiWuInitialData.vue')
            },
            {
                path: '/pc/cashFlowInitialData',
                name: '现金流量初始数据',
                component: () =>
                    import ('@/views/financial/initialData/cashFlowInitialData.vue')
            },
            {
                path: '/pc/accountInitialData',
                name: '账户初始数据',
                component: () =>
                    import ('@/views/financial/initialData/accountInitialData.vue')
            },
            {
                path: '/pc/closingCarryForward',
                name: '期末结转',
                component: () =>
                    import ('@/views/financial/certificate/closingCarryForward.vue')
            },
            {
                path: '/pc/balanceSheet',
                name: '资产负债表',
                component: () =>
                    import ('@/views/financial/reportForm/balanceSheet.vue')
            },
            {
                path: '/pc/profitSheet',
                name: '利润表',
                component: () =>
                    import ('@/views/financial/reportForm/profitSheet.vue')
            },
            {
                path: '/pc/cashFlowSheet',
                name: '现金流量表',
                component: () =>
                    import ('@/views/financial/reportForm/cashFlowSheet.vue')
            },
            {
                path: '/pc/checkOut',
                name: '结账',
                component: () =>
                    import ('@/views/financial/certificate/checkOut.vue')
            },
            {
                path: '/pc/checkOutDet',
                name: '结账前检查明细',
                component: () =>
                    import ('@/views/financial/certificate/checkOutDet.vue')
            },
            {
                path: '/pc/collateVoucher',
                name: '整理凭证',
                component: () =>
                    import ('@/views/financial/certificate/collateVoucher.vue')
            },
            {
                path: '/pc/voucherSummary',
                name: '凭证汇总表',
                component: () =>
                    import ('@/views/financial/certificate/voucherSummary.vue')
            },
            {
                path: '/pc/subLedger',
                name: '明细账',
                component: () =>
                    import ('@/views/financial/certificate/subLedger.vue')
            },
            {
                path: '/pc/subjectBalanceSheet',
                name: '科目余额表',
                component: () =>
                    import ('@/views/financial/certificate/subjectBalanceSheet.vue')
            },
            {
                path: '/pc/generalLedger',
                name: '总分类账',
                component: () =>
                    import ('@/views/financial/certificate/generalLedger.vue')
            },
            {
                path: '/pc/settlementUnit',
                name: '结算单位',
                component: () =>
                    import ('@/views/financial/accountManagement/settlementUnit.vue')
            },
            {
                path: '/pc/projectProfitSheet',
                name: '项目利润表',
                component: () =>
                    import ('@/views/financial/reportForm/projectProfitSheet.vue')
            },
           
        ]
    },
    {
        path: '/pc/serviceType',
        name: '服务类型详情',
        component: () =>
            import ('@/views/setting/serviceType.vue'),
    },
    {
        path: '/login',
        name: '扫码登录',
        component: () =>
            import ('@/views/index/login.vue'),
    },
    {
        path: '/pc/taskFormDetail',
        name: '指令任务',
        component: () =>
            import ('@/views/commandTask/taskFormDetail.vue'),
    },
    {
        path: '/pc/approvalFormDetail',
        name: '下游审批单',
        component: () =>
            import ('@/views/stream/ApprovalFormDetail.vue'),
    },
    {
        path: '/404',
        component: () => import('@/views/error-page/404'),
        hidden: true
    },
    { path: '*', redirect: '/404', hidden: true }
];

routes = constantRoutes.concat(routes)

const router = new VueRouter({
    routes,
    mode: 'hash',
});
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

export default router;
